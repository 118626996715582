<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="title"
            :counter="20"
            label="Título"
            required
          />
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="store"
            :counter="10"
            label="Loja"
            required
          />
        </v-col>

        <v-col cols="12" md="4">
          <v-select
            v-model="select"
            :items="items"
            label="Status"
            required
          />
        </v-col>
      </v-row>

      <v-row>
        <v-btn
          small
          color="error"
          class="mr-4"
          @click="reset"
        >
          Limpar
        </v-btn>

        <v-btn
          small
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="validate"
        >
          Buscar
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script lang="js">

export default {
  name: "FilterOffers",

  data: () => ({
    valid: true,
    title: '',
    store: '',
    select: null,
    items: [
      'Ativo',
      'Inativo',
    ],
    checkbox: false,
  }),

  methods: {
    validate () {
      this.$refs.form.validate()
    },

    reset () {
      this.$refs.form.reset()
    }
  },
}
</script>

<style scoped>

</style>